// IIFE called when script is loaded
// Immediately invoked function expression
( function initModule() {
    const elementsWithTooltip = [ ...document.querySelectorAll( '.js-tooltip' ) ];

    elementsWithTooltip.forEach( element => {
        const parentIsInput = element.parentNode.classList.contains( 'icon-input' );

        /*eslint-disable no-undef*/
        if ( parentIsInput ) {
            const input = element.parentNode.querySelector( 'input' );
            const inputIsDisabled = input.hasAttribute( 'disabled' );

            if ( !inputIsDisabled ) {
                tippy( element, {
                    arrow: true,
                    trigger: 'click',
                    placement: 'top-end'
                } );
            }

        } else {
            tippy( element, {
                arrow: true,
                trigger: 'click'
            } );
        }
        /*eslint-enable no-undef*/
    } );
} )();
